import Vuex from 'vuex';
import AppModule from '@/shared/store-modules/app/index';
import VendorModule from '@/shared/store-modules/vendor/index';
import VenueModule from '@/shared/store-modules/venue/index';
import CalendarModule from '@/shared/store-modules/calendar';
import HistoryModule from '@/shared/store-modules/history/index';
import loaderStore from '@/config/store/loader.store';
import authenticationModule from '@/Authentication';
import BookingModule from '@/shared/store-modules/booking/index';
import createPersistedState from 'vuex-persistedstate';
const vuexStorePlugins = [];
if (process.browser) {
    vuexStorePlugins.push(createPersistedState({
        paths: ['authentication', 'booking']
    }));
}
/**
 * Setup global Vuex store
 * for Vue3: https://v3.vuejs.org/guide/migration/global-api.html#vue-prototype-replaced-by-config-globalproperties
 */
export const setupVuexStore = () => {
    return new Vuex.Store({
        modules: {
            $_app: AppModule,
            $_vendor: VendorModule,
            $_venue: VenueModule,
            $_calendar: CalendarModule,
            $_history: HistoryModule,
            loader: loaderStore,
            authentication: authenticationModule.store,
            booking: BookingModule
        },
        actions: {
            async initStore({ dispatch }) {
                await dispatch('$_venue/getCategories');
            }
        },
        plugins: vuexStorePlugins
    });
};
