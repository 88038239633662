var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"time-filter"},[_c('DatePicker',{staticClass:"time-filter-calendar",style:({
      'background-color': '#FFF',
      border: 'none',
      height: 'min-content'
    }),attrs:{"is-inline":"","locale":_vm.locale,"attributes":_vm.attributes,"min-date":new Date(),"theme":_vm.themeStyles,"select-attribute":_vm.selectAttribute},scopedSlots:_vm._u([{key:"header-title",fn:function(ref){
    var monthLabel = ref.monthLabel;
    var yearLabel = ref.yearLabel;
return _c('div',{staticClass:"header-title-slot"},[_c('span',{staticClass:"header-title-slot-year"},[_vm._v(_vm._s(yearLabel))]),_c('span',{staticClass:"header-title-slot-month"},[_vm._v(_vm._s(monthLabel))])])}}]),model:{value:(_vm.currentDay),callback:function ($$v) {_vm.currentDay=$$v},expression:"currentDay"}},[_c('i',{staticClass:"fa-solid fa-arrow-left fa-lg arrows",attrs:{"slot":"header-left-button"},slot:"header-left-button"}),_c('i',{staticClass:"fa-solid fa-arrow-right fa-lg arrows",attrs:{"slot":"header-right-button"},slot:"header-right-button"})]),_c('section',{staticClass:"time-filter__period"},[_c('ul',{staticClass:"time-filter-hour"},_vm._l((_vm.ranges),function(range){return _c('WMRadio',{key:range.name,staticClass:"time-filter__radio-item",attrs:{"id":range.name,"value":range.name,"checked":_vm.times[range.name].checked},on:{"input":function($event){return _vm.onRangeRadioChecked(range.name, true)}}},[_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_c('div',{staticClass:"time-filter-radio"},[_c('div',{staticClass:"time-filter-radio__description"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t(("filter.time." + (range.name) + ".title")))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("moment")(range.slotFrom,'HH:mm'))+" – "+_vm._s(_vm._f("moment")(range.slotTo,'HH:mm')))])]),_c('div',{staticClass:"time-filter-radio__icon"},[_c('i',{class:("fa-solid fa-" + (_vm.$t(
                    ("filter.time." + (range.name) + ".icon")
                  )) + " fa-sm")})])])]},proxy:true}],null,true)})],1)}),1),_c('div',{staticClass:"time-filter-custom-time"},[_c('div',{staticClass:"time-filter-custom-time-selection"},[_c('FormSelect',{attrs:{"value":_vm.customRange.slotFrom,"position":_vm.$mq && _vm.$mq.phone ? 'top' : 'bottom',"placeholder":_vm.$t('filter.time.custom_time.placeholder.from'),"options":_vm.startHours},on:{"input":function($event){return _vm.updateCustomRange(Object.assign({}, _vm.customRange, {slotFrom: $event}))}}}),_c('FormSelect',{attrs:{"value":_vm.customRange.slotTo,"position":_vm.$mq && _vm.$mq.phone ? 'top' : 'bottom',"placeholder":_vm.$t('filter.time.custom_time.placeholder.to'),"options":_vm.endHours},on:{"input":function($event){return _vm.updateCustomRange(Object.assign({}, _vm.customRange, {slotTo: $event}))}}})],1)]),_c('div',{staticClass:"time-filter-control"},[_c('WMButton',{attrs:{"small":"","color":"danger","icon-class-name":"fa-solid fa-xmark fa-sm"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.close')))])]),_c('WMButton',{attrs:{"small":"","ghost":"","color":"danger","icon-class-name":"fa-solid fa-repeat fa-sm"},on:{"click":function($event){return _vm.$emit('reset')}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.reset')))])]),_c('WMButton',{attrs:{"small":"","icon-class-name":"fa-solid fa-check fa-sm"},on:{"click":function($event){return _vm.$emit('submit')}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.apply')))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }