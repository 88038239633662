// https://stackoverflow.com/questions/60619067/vue-js-and-es7-referenceerror-regeneratorruntime-not-defined
import 'core-js/stable'; // only stable feature also is possible with only `core-js`
import 'regenerator-runtime/runtime'; // To ensure that regeneratorRuntime is defined globally
import 'vue-toastification/dist/index.css';
import Vue from 'vue';
import i18n from '@/i18n/i18n-setup';
import App from '@/App.vue';
import { provide } from '@vue/composition-api';
import { ApolloClients } from '@vue/apollo-composable';
import { createApolloClients } from '@/gql';
import { registerGlobalComponents, registerGlobalFilters, registerGlobalPlugins, registerProperties, registerDirectives, setupVuexStore, setupGlobalThis, setupRouter } from '@/config';
registerGlobalFilters();
registerGlobalComponents();
if (process.browser) {
    registerGlobalPlugins();
}
registerProperties();
registerDirectives();
// Expose a factory function that creates a fresh set of store, router,
function createApp() {
    const store = setupVuexStore();
    const router = setupRouter();
    setupGlobalThis(store, router, i18n);
    // Vuex state restoration
    if (window.Cypress) {
        Vue.prototype.Cypress = window.Cypress;
    }
    // Apollo
    const { apolloClient, legacyClient } = createApolloClients();
    // create the app instance.
    const app = new Vue({
        i18n,
        router,
        store,
        setup(_) {
            provide(ApolloClients, {
                default: apolloClient,
                legacy: legacyClient
            });
            return {};
        },
        mq: {
            mini: '(max-width: 480px)',
            phone: '(max-width: 960px)',
            tablet: '(min-width: 961px) and (max-width: 1200px)',
            desktop: '(min-width: 1201px)'
        },
        render: h => h(App)
    });
    // expose the app, the router and the store.
    // note we are not mounting the app here
    return { app, router, store, apolloClient, legacyClient };
}
export default createApp;
