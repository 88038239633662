import { TYPE } from 'vue-toastification';
const options = {
    bodyClassName: 'toast-message-text',
    closeButtonClassName: 'toast-close',
    showCloseButtonOnHover: true,
    timeout: 5000,
    toastDefaults: {
        [TYPE.ERROR]: {
            icon: 'fa-solid fa-exclamation fa-lg',
            toastClassName: ['toast-message', 'toast-message-error'],
        },
        [TYPE.INFO]: {
            icon: 'fa-solid fa-smile fa-lg',
            toastClassName: ['toast-message', 'toast-message-info'],
        },
        [TYPE.SUCCESS]: {
            icon: 'fa-solid fa-check fa-lg',
            toastClassName: ['toast-message', 'toast-message-success'],
        }
    }
};
export default options;
