import { SpaceRoutes } from '@/Space/space.const';
import SpaceHeader from '@/Space/components/SpaceHeader.vue';
import SearchHeader from '@/Search/components/SearchHeader.vue';
/** Components */
const SpacePage = () => import(/* webpackChunkName: "space.route" */ '@/Space/SpacePageWrapper.vue');
export const spaceRoutes = [
    {
        path: `/${SpaceRoutes.SPACE}/:hash/:name/:reference`,
        localePath: `${SpaceRoutes.SPACE}/:hash/:name/:reference`,
        meta: {
            breadcrumbs: SpaceHeader,
            header: SearchHeader
        },
        component: SpacePage
    },
    // matches only the invalid space page URLs like https://wemeet.nl/gr5BxZ3u/addflex4you-almere/25706
    // and redirects to the correct URL https://wemeet.nl/customer/space/gr5BxZ3u/addflex4you-almere/25706
    {
        path: '/:hash([a-z0-9]{8})/:name/:reference(\\d+)', // Redirects from the invalid routes, needed for SEO
        localePath: ':hash([a-z0-9]{8})/:name/:reference(\\d+)',
        redirect: to => {
            if (to.params.hash !== 'bookings') {
                return `${SpaceRoutes.SPACE}/${to.params.hash}/${to.params.name}/${to.params.reference}`;
            }
            return `${to.params.hash}/${to.params.name}`;
        }
    }
];
