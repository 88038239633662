import Vue from 'vue';
import { defineComponent, ref, computed, onMounted, onUpdated } from '@vue/composition-api';
import debounce from 'lodash/debounce';
export default defineComponent({
    name: 'Input',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: [String, Number],
            default: ''
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: [String, Object],
            default: ''
        },
        error: {
            type: [String, Boolean, Object],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        maxLength: {
            type: Number,
            default: 255
        },
        inputType: {
            type: String,
            default: 'text'
        },
        required: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: Array,
            default: () => null
        },
        prepend: {
            type: String,
            default: ''
        },
        append: {
            type: String,
            default: ''
        },
        appendClass: {
            type: String,
            default: ''
        },
        rightIconClass: {
            type: String,
            default: ''
        },
        leftIcon: {
            type: String,
            default: ''
        },
        leftIconClass: {
            type: String,
            default: ''
        },
        onIconClicked: {
            type: Function,
            default: () => { }
        },
        desc: {
            type: [String, Object],
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        },
        max: {
            type: Number
        },
        noResults: {
            type: [String, Object],
            default: 'No results'
        },
        textValueWhenZero: {
            type: Boolean,
            default: false
        },
        autofocused: {
            type: Boolean,
            default: false
        },
        inputNumberMinZero: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const closeSuggestions = ref(false);
        const focused = ref(false);
        const prependOffset = ref(0);
        const optionItem = ref(null);
        const prependBlock = ref(null);
        const inputElement = ref(null);
        const inputTypeComputed = computed(() => {
            if (props.inputType === 'number' || props.inputType === 'code')
                return 'text';
            return props.inputType;
        });
        const zeroResults = computed(() => {
            return (focused.value && props.autocomplete && props.autocomplete.length === 0);
        });
        const onFocus = (event) => {
            closeSuggestions.value = false;
            focused.value = true;
            emit('focus', event);
        };
        const onBlur = (event) => {
            focused.value = false;
            emit('blur', event);
            debounce(() => (closeSuggestions.value = true), 500)();
        };
        const checkInput = (evt) => {
            if (evt.charCode === 13 &&
                evt.target.offsetParent.prevClass !== 'input-group')
                evt.preventDefault();
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (`${evt.target.value}${evt.key}`.length > props.maxLength) {
                evt.preventDefault();
            }
            if (props.inputType === 'number' || props.inputType === 'code') {
                if (charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 46) {
                    evt.preventDefault();
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
            return true;
        };
        const onChange = (event) => {
            let inputVal;
            if (props.inputType === 'number') {
                if (Number(event.target.value) > props.max) {
                    event.target.value = event.target.value.slice(0, -1);
                    return;
                }
                if (props.inputNumberMinZero) {
                    event.target.value =
                        event.target.value === '' ? 0 : Number(event.target.value);
                    inputVal = Number(event.target.value);
                }
                else {
                    inputVal =
                        event.target.value === '' ? '' : Number(event.target.value);
                }
            }
            else {
                inputVal = event.target.value;
            }
            emit('input', inputVal);
        };
        const selectOption = (id, label) => {
            emit('autocompleteSelected', id, label);
        };
        const onKeyUp = (event) => {
            emit('keyup', event);
            if (['ArrowUp', 'ArrowDown', 'Enter'].includes(event.code) &&
                optionItem.value) {
                let selectedOptionFound = false;
                for (let i = 0; i < props.autocomplete.length; i += 1) {
                    if (props.autocomplete[i].selected) {
                        selectedOptionFound = true;
                        if (event.code === 'ArrowUp') {
                            if (props.autocomplete[i - 1]) {
                                Vue.set(props.autocomplete[i - 1], 'selected', true);
                                Vue.set(props.autocomplete[i], 'selected', false);
                            }
                            else {
                                Vue.set(props.autocomplete[props.autocomplete.length - 1], 'selected', true);
                                Vue.set(props.autocomplete[0], 'selected', false);
                            }
                            emit('autocompleteDeselected', i);
                            break;
                        }
                        if (event.code === 'ArrowDown') {
                            if (props.autocomplete[i + 1]) {
                                Vue.set(props.autocomplete[i + 1], 'selected', true);
                                Vue.set(props.autocomplete[i], 'selected', false);
                            }
                            else {
                                Vue.set(props.autocomplete[0], 'selected', true);
                                Vue.set(props.autocomplete[props.autocomplete.length - 1], 'selected', false);
                            }
                            emit('autocompleteDeselected', i);
                            break;
                        }
                        if (event.code === 'Enter') {
                            emit('autocompleteSelected', props.autocomplete[i].id, props.autocomplete[i].label);
                            inputElement.value.blur();
                            break;
                        }
                    }
                }
                if (!selectedOptionFound && event.code === 'ArrowDown') {
                    Vue.set(props.autocomplete[0], 'selected', true);
                }
                if (!selectedOptionFound && event.code === 'ArrowUp') {
                    Vue.set(props.autocomplete[props.autocomplete.length - 1], 'selected', true);
                }
            }
        };
        onMounted(() => {
            if (props.autofocused) {
                // Wait for the next update cycle before accessing the input element
                root.$nextTick(() => {
                    inputElement.value.focus();
                });
            }
            if (prependBlock.value) {
                prependOffset.value = prependBlock.value.clientWidth;
            }
        });
        onUpdated(() => {
            if (props.prepend && prependBlock.value) {
                prependOffset.value = prependBlock.value.clientWidth;
            }
            else {
                prependOffset.value = 0;
            }
        });
        return {
            closeSuggestions,
            focused,
            inputElement,
            inputTypeComputed,
            optionItem,
            prependBlock,
            prependOffset,
            zeroResults,
            checkInput,
            onBlur,
            onChange,
            onFocus,
            onKeyUp,
            selectOption
        };
    }
});
