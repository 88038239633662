import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    name: 'CustomButton',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        borderless: {
            type: Boolean,
            default: false
        },
        iconOnly: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'submit'
        },
        ghost: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary'
        },
        straight: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        green: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        iconClassName: {
            type: String,
            default: ''
        },
        title: {
            type: [String, Object],
            default: ''
        },
        danger: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const iconIsSocialNetwork = computed(() => {
            return [
                'facebook',
                'x',
                'linkedin',
                'pinterest',
                'google',
                'youtube'
            ].includes(props.icon);
        });
        return { iconIsSocialNetwork };
    }
});
